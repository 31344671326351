.progress-bar {
    width: 80%;
    margin: 20px auto;
  }
  
  .progress-container {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #ccc;
    position: relative;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
  }
  
  .progress-indicator {
    height: 100%;
    background-color: #f39c12;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .progress-label {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  
  .label {
    flex: 1;
    text-align: center;
  }