  
.agents-details{
    margin-bottom: 0px;
}
.contant-header{
    background: #f1f1f1; 
    padding:100px 0 30px;
}

.stretch-card{
    margin:20px 0 0 0;
}
.card-boarder{
    border-radius: 20px;
}
.form-fields{
    padding: 20px 1.25rem 1.25rem ;
}
.assigned-vehicles{
    background: #f1f1f1;padding: 20px;margin-top: 10px;
}
.tarce-admin{
    color: #000;
}
.search-button{
    margin-top: 4px;
    width: 153%;
    height: 45px;
}